import React, { ChangeEvent, FC } from "react";
import styled from "styled-components";

interface FormulaInputProps {
    id?: string;
    value: string | number;
    onChange: (params: ChangeEvent<HTMLInputElement>) => void;
    handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    isBottom?: boolean;
    className?: string;
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
    placeholder?: string | undefined;
}

const CutLengthsInput = styled.input`
    ::placeholder {
        color: #C2C2C2; 
    }
`;

const FormulaInput: FC<FormulaInputProps> = ({
    id,
    value,
    onChange,
    isBottom = false,
    className = "",
    handleKeyDown,
    onFocus,
    placeholder
}) => {
    return (
        <div className={`${isBottom ? "" : "border-b border-black/25"} h-14`}>
            <div className="flex flex-row h-full items-center justify-center p-2">
                <p className="text-sm mb-0 mr-2">
                    {id && id.includes("TrimHorizontal") ? "FW +" : "FL +"}
                </p>
                <CutLengthsInput
                    id={id}
                    name={id}
                    onChange={onChange}
                    onKeyDown={handleKeyDown}
                    value={value}
                    className="mr-2 rounded items-center border w-12 h-6 p-1"
                    step={0.001}
                    type="number"
                    onFocus={onFocus}
                    placeholder={placeholder}
                />
                <p className="text-sm mb-0">in.</p>
            </div>
        </div>
    );
};

export default FormulaInput;
